import React from 'react';
import ProductCategoryRow from "./ProductCategoryRow";
import ProductRow, {productType} from "./ProductRow";
import {JSX} from 'react/jsx-runtime';

function ProductTable({products, filterText, inStockOnly}
                          : { filterText: string, inStockOnly: boolean, products: productType[] }) {
    const rows: JSX.Element[] = [];
    let lastCategory: string | null = null;

    products.forEach((product) => {
    //return only filtered text
        if (
            product.name.toLowerCase().indexOf(
                filterText.toLowerCase()
            ) === -1
        ) {
            return;
        }
        // if instock only, return only in stock
        if (inStockOnly && !product.stocked) {
            return;
        }
        //category or product text
        if (product.category !== lastCategory) {
            rows.push(
                <ProductCategoryRow
                    category={product.category}
                    key={product.category}/>
            );
        }
        rows.push(
            <ProductRow
                product={product}
                key={product.name}/>
        );
        lastCategory = product.category;
    });
    return (
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Price</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
}

export default ProductTable;
