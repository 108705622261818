import React from 'react';
// import {productType} from "./ProductCategoryRow";

export type productType={
    stocked:boolean,
    name:string,
    price:string,
    category:string
};

type fullProductType ={
    product:productType
}

//note: if "productType" is imported, so need a new type wrapping
//and use like {product}:fullProductType)
//otherwise caller can have problem.
//
function ProductRow({product}:fullProductType) {
  const name = product.stocked
      ? product.name
      //add style when not stocked
      :<span style={{ color: 'red' }}>
      {product.name}
    </span>;

  return (
      <tr>
        <td>{name}</td>
        <td>{product.price}</td>
      </tr>
  );
}

export default ProductRow ;
