import React from 'react';

type SearchBarInputs={
    filterText:string,
    inStockOnly:boolean
    onFilterTextChange:Function
    onInStockOnlyChange:Function
}

  function SearchBar({filterText,inStockOnly,onFilterTextChange,onInStockOnlyChange}
                           :SearchBarInputs) {
    //the onchange is an event, so have to use Target.Value
      //checkbox type do not use value, use checked
  return (
      <form>
        <input type="text"
               value={filterText}
               placeholder="Search..."
               onChange={(e)=>onFilterTextChange(e.target.value)} />
        <label>
          <input type="checkbox"
                 checked={inStockOnly}
                 onChange={(e)=>onInStockOnlyChange(e.target.checked)}/>
          {/*{' '}*/}
          Only show products in stock
        </label>
      </form>
  );
}
export default SearchBar;
