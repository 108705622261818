import React,{useState} from 'react';
import './FilterableProductTable.css';
import SearchBar from "./SearchBar";
import ProductTable from "./ProductTable";
import data from "./data.json"; // just take the name from the json file?


function FilterableProductTable() {

    const [filterText, setFilterText] = useState('');
    const [inStockOnly, setInStockOnly] = useState(false);


    //note: products={data} will cause error, as data is not directly a referece for productType[]
    // need to use {[...data]} to separate data and wrap again in a new array
    return (
        <div>
            <SearchBar filterText={filterText}
                       inStockOnly={inStockOnly}
                       onFilterTextChange={setFilterText}
                       onInStockOnlyChange={setInStockOnly}
            />
            <ProductTable products={[...data]}
                          filterText={filterText}
                          inStockOnly={inStockOnly}/>
        </div>
    );
}

export default FilterableProductTable;
